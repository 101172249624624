import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const UpgradesMenu = () => {

    const auth = useSelector(state => state.auth);

    const [memberships, setMemberships] = useState([]);

    useEffect(()=>{

       if(auth.user &&auth.user.membership !== null)
           setMemberships(auth.user.membership.split('__'))

    },[auth.user.membership])

    return (
        <>
            <div className="col-md-4">
                <div className="upgrade-menu mt-5">
                    <div className="nav flex-column " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {
                            memberships.includes('professional') || memberships.includes('professionallite') || memberships.includes('business') ?
                                <a className="nav-link" id="v-pills-wlrebranding-tab" data-toggle="pill"
                                   href="#v-pills-wlrebranding" role="tab" aria-controls="v-pills-wlrebranding"
                                   aria-selected="false">Agency Rebranding</a>
                            : null
                        }
                        <a className="nav-link" id="v-pills-media-tab" data-toggle="pill"
                           href="#v-pills-media" role="tab" aria-controls="v-pills-media"
                           aria-selected="false">Media Library</a>
                        <a className="nav-link" id="v-pills-business-tab" data-toggle="pill" href="#v-pills-business"
                           role="tab" aria-controls="v-pills-business" aria-selected="false">Business Finder</a>
                        <a className="nav-link" id="v-pills-fbtemplate-tab" data-toggle="pill"
                           href="#v-pills-fbtemplate" role="tab" aria-controls="v-pills-fbtemplate"
                           aria-selected="true">FB Templates</a>
                        {/* <a className="nav-link" id="v-pills-vidoeflix-tab" data-toggle="pill" href="#v-pills-vidoeflix"
                           role="tab" aria-controls="v-pills-vidoeflix" aria-selected="false">Video Flix</a> */}

                        {
                            memberships.includes('unlimited') || memberships.includes('unlimitedlite') ?
                                <a className="nav-link" id="v-pills-udamy-tab" data-toggle="pill" href="#v-pills-udamy"
                                   role="tab" aria-controls="v-pills-udamy" aria-selected="false">Acadeable</a>
                            : null
                        }
                        {/* <a className="nav-link" id="v-pills-dfycourse-tab" data-toggle="pill" href="#v-pills-dfycourse"
                           role="tab" aria-controls="v-pills-dfycourse" aria-selected="false">DFY Course</a> */}
                        {
                            memberships.includes('business') ?
                                <>
                                    <a className="nav-link" id="v-pills-client-tab" data-toggle="pill" href="#v-pills-client"
                                       role="tab" aria-controls="v-pills-client" aria-selected="false">Client Contract</a>
                                    <a className="nav-link" id="v-pills-reseller-tab" data-toggle="pill" href="#v-pills-reseller"
                                       role="tab" aria-controls="v-pills-reseller" aria-selected="false">Reseller License</a>
                                    <a className="nav-link" id="v-pills-webhosting-tab" data-toggle="pill"
                                       href="#v-pills-webhosting" role="tab" aria-controls="v-pills-webhosting"
                                       aria-selected="false">Web Hosting</a>

                                    <a className="nav-link" id="v-pills-overlay-tab" data-toggle="pill"
                                    href="#v-pills-overlay" role="tab" aria-controls="v-pills-overlay"
                                    aria-selected="false">Overlay</a>

                                    <a className="nav-link" id="v-pills-videocommercial-tab" data-toggle="pill"
                                       href="#v-pills-videocommercial" role="tab" aria-controls="v-pills-videocommercial"
                                       aria-selected="false">Video Commercial</a>
                                    <a className="nav-link" id="v-pills-agency-tab" data-toggle="pill" href="#v-pills-agency"
                                       role="tab" aria-controls="v-pills-agency" aria-selected="false">Agency Website</a>
                                </>
                            : null
                        }
                        {/*<a className="nav-link" id="v-pills-videopromo-tab" data-toggle="pill"*/}
                        {/*   href="#v-pills-videopromo" role="tab" aria-controls="v-pills-videopromo"*/}
                        {/*   aria-selected="false">Video Promos</a>*/}
                        <a className="nav-link" id="v-pills-dfylead-tab" data-toggle="pill" href="#v-pills-dfylead"
                           role="tab" aria-controls="v-pills-dfylead" aria-selected="false">DFY Lead Magnets</a>
                        <a className="nav-link" id="v-pills-social-tab" data-toggle="pill" href="#v-pills-social"
                           role="tab" aria-controls="v-pills-social" aria-selected="false">Social Media Assets</a>
                        <a className="nav-link" id="v-pills-dfyarticles-tab" data-toggle="pill"
                           href="#v-pills-dfyarticles" role="tab" aria-controls="v-pills-dfyarticles"
                           aria-selected="false">DFY Articles</a>
                        {
                            memberships.includes('bundle') ||  memberships.includes('playerneos')|| memberships.includes('dfy')?
                                <a className="nav-link" id="v-pills-dfycourses-tab" data-toggle="pill"
                                   href="#v-pills-dfycourses" role="tab" aria-controls="v-pills-dfycourses"
                                   aria-selected="false">DFY Courses</a>
                            : null
                        }

        
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpgradesMenu;